// cSpell:ignore feminin
import React from 'react';
import styled from 'styled-components';

import ArticlesSelector from './v2/ArticlesSelector';
import PressArticle from './PressArticle';
import Dots from './v2/Dots';
import {
  margins, fontSizes, colors, mobileThresholdPixels, Title2,
} from './v2/StyledComponents';
import rtlImg from '../../assets/press/rtl.png';
import figaroMadameImg from '../../assets/press/figaro-madame.png';
import figaroMadameArticleImg from '../../assets/press/figaro-madame-article.jpg';
import rudeBaguetteImg from '../../assets/press/rudeBaguette-logo.jpg';
import cosmoImg from '../../assets/press/cosmo.svg';
import cosmoArticleImg from '../../assets/press/cosmo-article.jpg';
import viePratiqueFemininImg from '../../assets/press/vie-pratique-feminin.png';
import viePratiqueFemininArticleImg from '../../assets/press/vie-pratique-feminin-article.jpg';
import gustaveEtRosalieImg from '../../assets/press/gustave-et-rosalie.png';
import stellaParisImg from '../../assets/press/stellaParis.jpg';
import bonChicBelleGueuleImg from '../../assets/press/bonChicBelleGueule.png';
import maddynessImg from '../../assets/press/maddyness.png';
import mlleDentelleImg from '../../assets/press/mlle-dentelle.png';
import lciImg from '../../assets/press/LCI.png';
import wityImg from '../../assets/press/wity.png';
import weCasaImg from '../../assets/press/weCasa.png';
import zankyouImg from '../../assets/press/zankyou.png';
import toutMaImg from '../../assets/press/toutMa.png';
import toutMaArticleImg from '../../assets/press/toutMa-article.png';

const Title1 = styled(Title2.withComponent('h1'))``;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 30px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.navy};
  margin: 130px 0px ${margins.xl} 0px ;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
    border-width: 0px;
  }
`;

const SelectorContainer = styled.div`
  background-color: ${colors.white};
  margin-top: -103px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px ${margins.s};
  }
`;

const P = styled.p`
  color: ${colors.navy};
  font-size: ${fontSizes.l};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
    text-align: center;
  }
`;

const articlesList = [
  {
    index: 0,
    logo: rtlImg,
    logoCaption: 'Service retouches Paris',
    articles: [
      {
        title: 'Tilli, Cowash et The Keepers : ces start-up qui nous facilitent la vie !',
        content: (
          <div>
            <P>
              {`Des services de proximité, qu'on utilise depuis longtemps prennent un coup de jeune
              grâce aux nouvelles technologies afin de séduire une nouvelle clientèle, les jeunes
              actifs. Tilli, par exemple permet de mettre en contact via son site Internet ou son
              application, des particuliers et des couturiers, que ce soit pour une simple retouche ou
              des travaux plus complexes.`}
            </P>

            <P>
              {`L'idée est venue à une des fondatrices, alors qu'elle avait 8 mariages dans
              l'été et pas envie
              d'acheter 8 robes. Donc elle a fait appelle à une couturière pour remettre au
              goût du jour des vieux vêtements oubliés au fond de son armoire.`}
            </P>

            <P>
              {`Bien sûr, on a tous des couturiers dans son quartier mais ils ne sont pas toujours
              ouverts quand on veut. Avec Tilli, vous passez votre commande sur le site ou
              l'application quand vous voulez, vous définissez vos besoins - bouton, fermeture éclair
              ou quelque chose d'autre plus compliqué, comme mettre une doublure à une jupe - et vous
              prenez rendez-vous avec un couturier. Il y en a une vingtaine sélectionnée, qui
              travaillent 7 jours sur 7 et de 9H à 22H.`}
            </P>

            <P>
              {`Le couturier vient chercher le travail à domicile et vous le rapporte entre 2 et 5 jours
              plus tard. Le service est disponible pour l'instant sur Paris. Il le sera dans un mois à
              Marseille et d'ici la fin de l'année dans d'autres grandes villes.`}
            </P>
          </div>
        ),
        articleLink: 'http://www.rtl.fr/actu/futur/tilli-cowash-et-the-keepers-ces-start-up-qui-nous-facilitent-la-vie-7793406675', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 1,
    logo: maddynessImg,
    logoCaption: 'Ourlet de pantalon de tailleur Pigalle',
    articles: [
      {
        title: 'Tilli lève 400 000 euros pour développer son réseau de couturiers à domicile',
        content: (
          <div>
            <P>
              La plateforme Tilli, qui propose un service de retouche à domicile,
              a convaincu plusieurs business angels de renom.
              Elle cherche désormais à multiplier son réseau de marques partenaires
              et calque son développement géographique sur les besoins de ces dernières.
            </P>
            <P>
              Première opération financière d’envergure pour Tilli.
              La plateforme de couture à domicile vient de boucler un tour de table
              de 400 000 euros auprès de plusieurs business angels de renom :
              Aurélien de Meaux (Cheerz), Elie Kouby et Frédéric Biousse (Experience Capital),
              Andrew de Murga (Wexford Group, Polène) et Regis Medina (Keenly).
              Cela doit permettre à l’entreprise de développer à la fois son réseau de couturiers
              et son panel de marques partenaires.
            </P>
            <P>
              Tilli propose un service digitalisé de couturiers à domicile.
              La plateforme permet de prendre rapidement rendez-vous avec un couturier
              professionnel pour une retouche de vêtement ou d’élément de décoration comme
              des rideaux. L’entreprise combine ainsi l’expérience sur demande
              et à prix standardisés et transparents permise par le numérique avec la prestation
              personnalisée d’un métier dont le savoir-faire est méconnu et trop peu valorisé par
              les particuliers. Le service est accessible à ces derniers via une plateforme en
              ligne mais se développe principalement en B2B2C, via sa solution plug & play que
              les marques peuvent intégrer sur leur site pour pousser le service à leurs clients.
            </P>
          </div>
        ),
        articleLink: 'https://www.maddyness.com/2018/09/26/tilli-leve-400-000-euros/', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 2,
    logo: lciImg,
    logoCaption: 'Retoucheur Paris Montparnasse',
    articles: [
      {
        title: '"J‘ai pas le temps" : avec l‘appli Tilli, relookez votre garde-robe en restant chez vous !',
        content: (
          <div>
            <P>
              A DOMICILE - Donner une nouvelle vie à ses vieux vêtements sans bouger de chez soi,
              c‘est désormais possible grâce à Tilli, le premier service de retouches à commander en
              quelques clics. On a testé - et approuvé - le travail de ces couturières 2.0.
            </P>
            <P>
              Après une longue introspection dans notre garde-robe, on a exhumé un vieux jean noir
              beaucoup trop long et une chemise en jean à rajeunir. Après, rien de plus simple,
              il suffit d‘indiquer sa demande sur le site de Tilli, ainsi qu‘une date et un lieu
              de rendez-vous. Vingt-quatre heures plus tard, Marie venait récupérer les vêtements,
              non sans avoir vérifié auparavant via SMS que nous étions bien à la maison.
            </P>
            [...]
            <br />
            <P>
              Une fois le travail exécuté, la couturière peut être amenée à revenir chez vous,
              dans le cas d‘un ajustement ou d‘une transformation, sinon c‘est un service de
              livraison qui rapporte les vêtements retouchés. Comptez 12 € pour un ourlet simple
              et 22 € pour une broderie fait main + 5 € de frais de déplacement.
              Conclusion, un service épatant pour qui veut consommer ses vêtements de façon plus
              durable et des tarifs qui sont sensiblement les mêmes que ceux d‘une retoucherie classique.
            </P>
          </div>
        ),
        articleLink: 'https://www.lci.fr/conso-argent/ourlets-couture-j-ai-pas-le-temps-avec-l-appli-tilli-relookez-votre-garde-robe-en-restant-chez-vous-2082227.html', // eslint-disable-line max-len
      },
      {
        title: 'Pressing, courses, massage : 5 applis pour (enfin) se faciliter la vie !',
        content: (
          <div>
            <P>
              {`
              Un jean troué ou trop long ? Une robe fétiche qui a besoin d‘être revisitée ? Les zips de vos
              pulls qui cassent les uns après les autres ? Plus besoin d‘arpenter les rues de votre
              quartier en quête d‘une mercerie - le genre de boutiques devenues quasiment introuvables -
              faites appel au site Tilli, un service de retouches à domicile, le seul du genre sur
              Internet. Aux manettes Beryl de Labouchere, la fondatrice, dont l‘idée a germé il y a un an
              et demi : "J‘avais envie de donner une seconde chance à mes vêtements plutôt que de les jeter
              et d‘en acheter d‘autres, mais encore fallait-il avoir les compétences".
            `}
            </P>
            <P>
              L’idée de départ était de proposer une manière de consommer différemment sa garde-robe,
              de manière durable et créative !
              Nos clients se libèrent du temps et consomment leurs vêtements différemment, de façon
              plus durable. La plateforme Tilli.fr permet de remettre au goût
              du jour le métier de couturier et d’aller au-delà de la simple retouche pour laisser
              place à la créativité.
            </P>
          </div>
        ),
        articleLink: 'https://www.lci.fr/conso-argent/pressing-courses-massage-on-vous-a-selectionne-les-meilleures-applis-pour-se-faciliter-la-vie-culinaries-simone-tilli-lavoir-moderne-wecasa-2082212.html', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 3,
    logo: figaroMadameImg,
    logoCaption: 'Retouche robe de mariage',
    articles: [
      {
        title: '“SOS retouches”',
        content: (
          <div>
            <P>Tilli, service de retoucherie à domicile créé par Beryl de Labouchere.</P>
            <P>
              <b>D‘où ça vient ?</b>
              {' '}
              “J‘avais envie de donner une seconde vie à mes vêtements, mais je n‘avais pas vraiment les compétences.”
            </P>
            <P>
              <b>Comment ça marche ?</b>
              {' '}
              “Après que le client a indiqué sa demande (ourlet, retouche ...) sur le site ou l‘appli
              ainsi qu‘une date et un lieu de rendez-vous, une couturière vient récupérer les vêtements
              tout en offrant ses conseils de style.”
            </P>
            <P>
              <b>L‘astuce</b>
              {' '}
              “Il faut se laisser guider par l‘oeil avisé des couturières car toutes les transformations
              sont possibles, et les plus vieux modèles peuvent retrouver une seconde jeunesse.”
            </P>
          </div>
        ),
        articleImage: figaroMadameArticleImg,
        articleImageAlt: 'Article sur la retouche à domicile',
      },
    ],
  },
  {
    index: 4,
    logo: rudeBaguetteImg,
    logoCaption: 'Enjeux Numériques Digital Issues',
    articles: [
      {
        title: '#FrenchTechFriday: Tilli – When an ancestral skill meets digital',
        content: (
          <P>
            The start of Beryl’s story has a “four weddings and a funeral” feel to it
            (minus the funeral, thankfully). She was looking for an entrepreneurship
            opportunity on a year seven of her friends chose to get married.
            Rather than buy new clothes each time, she looked for someone to revamp
            her current wardrobe. This turned out to be harder than expected but she found
            a skilled seamstress who came over to her home, took measures, made suggestions
            and did a great job. Beryl wanted to launch a Fintech startup but when
            she witnessed the feedback when she shared her experience, her choice was made.
            Getting a decent sewing job done was a challenge for everyone.
            A good friend of hers, from Italy, would even return to her home country with
            clothes to have them mended there. The friend’s name is Tilli.
          </P>
        ),
        articleLink: 'http://www.rudebaguette.com/2018/09/28/frenchtechfriday-tilli-when-an-ancestral-skill-meets-digital/', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 5,
    logo: cosmoImg,
    logoCaption: 'Meilleure retouche de Paris',
    articles: [
      {
        content: (
          <P>
            Pas douée en couture ? Tilli.fr me sauve la mise avec un service de retouches à domicile pour
            ajuster ou transformer ma garde-robe.
          </P>
        ),
        articleImage: cosmoArticleImg,
        articleImageAlt: 'Article sur le couturier/la couturière à domicile',
      },
    ],
  },
  {
    index: 6,
    logo: viePratiqueFemininImg,
    logoCaption: 'Atelier de couture à Paris',
    articles: [
      {
        title: 'Couturier à domicile',
        content: (
          <P>
            Vous adorez cette petite robe mais elle n‘est pas tout à fait à votre taille ?
            La fermeture éclair de votre jean fétiche fatigue ? Appelez Tilli à la rescousse !
            C‘est le tout premier service de retouche à domicile. Il suffit de sélectionner sa
            retouche sur Tilli.fr pour obtenir un devis et prendre un rendez-vous avant d‘attendre
            qu‘un couturier sonne à votre porte. Disponible partout en France on l‘espère !
          </P>
        ),
        articleImage: viePratiqueFemininArticleImg,
        articleImageAlt: 'Article sur les retoucheurs à Paris',
      },
    ],
  },
  {
    index: 7,
    logo: gustaveEtRosalieImg,
    logoCaption: 'Votre expert retouche haut de gamme',
    articles: [
      {
        title: 'T‘as une (re)touche',
        content: (
          <P>
            Il y a des gens qu’un rien habille. Vous leur collez n’importe quelle robe sur
            le dos, n’importe quel costard sur les épaules et les voilà sapés comme jamais.
            Si, à tout hasard, vous ne faites pas partie de ces gens, il y a Tilli, très
            bon service de retouche à domicile. Vous vous rendez sur leur site et vous
            demandez, en quelques clics, à faire rafraîchir une robe fétiche ou cintrer
            une veste. Votre couturier(ère) arrive à l’heure choisie, chauffe ses aiguilles
            et laisse opérer la magie de ses talentueuses mains sur vos vêtements. Pour
            que vous aussi, un rien vous habille.
          </P>
        ),
        articleLink: 'https://www.gustave-et-rosalie.com/6-idees-pour-survivre-a-la-saison-des-mariages',
      },
    ],
  },
  {
    index: 8,
    logo: stellaParisImg,
    logoCaption: 'Retouche express à domicile Champs Elysées',
    articles: [
      {
        title: 'Sortir du cercle vicieux de la consommation : faire réparer ses vêtements chez soi',
        content: (
          <P>
            [...]
            <br />
            J‘ai vraiment adoré le service et je l‘utiliserai sûrement à nouveau ! J‘ai trouvé le contact
            super personnel, le travail de grande qualité et super rapide. La couturière m‘a même envoyé
            des sms avec des photos de différents choix de boutons pour ma robe bleue vintage. Et surtout
            c‘est super pratique de ne pas bouger de chez soi, et que les horaires soient tard le soir. Et
            je suis ravie de participer à un monde qui répare au lieu de jeter ! J‘ai pu mettre à ma taille
            toutes mes petites robes vintage, et reporter cette adorable petite robe à fleurs que j‘ai depuis
            des années et qui me semblait trop courte et que Khadija a allongé de manière super artistique !
            Bref, merci Tilli, et vive le monde de la retouche !
          </P>
        ),
        articleLink: 'http://www.stellaparis.com/2017/07/sortir-du-cercle-vicieux-de-la.html',
      },
    ],
  },
  {
    index: 9,
    logo: bonChicBelleGueuleImg,
    logoCaption: 'Cintrer une veste de costume Wagram',
    articles: [
      {
        title: 'Tilli, le service de retouches à domicile qui facilite la vie',
        content: (
          <div>
            <P>
              Et si faire retoucher son jean fétiche ou mettre au goût du jour la robe vintage de sa
              grand-mère était aussi simple que de commander un burger ou un taxi depuis son canapé ?
              Tilli est une nouvelle plateforme qui met en contact clients et couturières qualifiées pour
              retoucher, réajuster et même transformer sa garde-robe.
            </P>

            <P>
              <b>Tilli, pour l’amour des vêtements</b>
              <br />
              Beryl de Labouchere, dont le dressing débordait de pièces adorées mais abîmées,
              mal ajustées ou vieillottes, a un jour l’idée d’un service de retouches à domicile pratique,
              rapide et convivial, qu’on commanderait en quelques clics, en direct de son salon.
            </P>

            <P>
              Baignant dans le monde des start-up et de l’entrepreneuriat depuis plusieurs années,
              elle crée Tilli fin 2016, avec la ferme intention de rentrer dans les habitudes des
              amoureux de la fringue : « Les gens se préoccupent de plus en plus de la provenance de
              leurs vêtements et achètent de façon plus responsable. Ils jettent moins et sont ravis
              de pouvoir donner une nouvelle vie à leurs vieilles fringues. En plus de transformer la
              garde-robe des femmes comme des hommes, le but de Tilli est de mettre en avant les couturières
              et leur expertise. C’est un très beau métier qui mérite toute notre admiration. »
            </P>

            <P>
              <b>Tilli, comment ça marche ?</b>
              <br />
              Pour profiter des conseils avisés des couturières Tilli, baptisées affectueusement les
              « Tillistes », on commence par effectuer un devis sur la plateforme en ligne.
              Raccourcir un pantalon, élargir le dos d’une robe, cintrer une veste ou même faire broder
              le prénom de son amoureux sur sa chemise préférée, tout est possible !
            </P>

            <P>
              Une fois le devis validé, on sélectionne une date pour accueillir la professionnelle
              chez soi (Paris et petite couronne, pour le moment) et on laisse la magie des épingles
              opérer. La couturière repart avec le vêtement à retoucher et le rapporte une fois le
              travail terminé. Après un dernier essayage pour vérifier que tout va bien, le jean ou
              le manteau autrefois délaissé est prêt à faire son come-back.
            </P>
          </div>
        ),
        articleLink: 'http://www.bonchicbellegueule.com/decouverte-tilli-retouches-domicile/',
      },
    ],
  },
  {
    index: 10,
    logo: mlleDentelleImg,
    logoCaption: 'Retouche robes de mariage Marais',
    articles: [
      {
        title: 'Service de retouches à domicile',
        content: (
          <div>
            <P>
              Tilli, c’est le premier service de retouches à domicile ! Quelques clics sur la plateforme
              www.tilli.fr et les Tillistes, tous couturiers de métiers, se déplacent à domicile pour retoucher,
              réajuster ou transformer toutes les pièces qui composent une garde-robe.
            </P>
            <P>
              L’idée de départ était de proposer une manière de consommer différemment sa garde-robe,
              de manière durable et créative ! Nos clients se libèrent du temps et consomment leurs vêtements
              différemment, de façon plus durable. La plateforme tilli.fr permet de remettre au goût du jour le
              métier de couturier et d’aller au-delà de la simple retouche pour laisser place à la créativité.
            </P>
          </div>
        ),
        articleLink: 'http://www.mademoiselle-dentelle.fr/prestataires/tilli/', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 11,
    logo: wityImg,
    logoCaption: 'Retoucheur couturier Paris Marais',
    articles: [
      {
        title: '#wityStartUpDay : a la découverte de Tilli, le service de retouches à domicile',
        content: (
          <div>
            <P>
              {`
              Et si retoucher une robe avant une soirée ou un vieux jean pour le mettre au goût du jour
              était aussi simple que de se faire livrer son déjeuner ? Créé en 2016, Tilli est une
              plateforme qui met en contact clients et couturières qualifiées pour retoucher, réajuster et
              même transformer sa garde-robe à domicile.
              `}
            </P>
            <P>
              Chez WITY, nous avons la chance de compter Tilli parmi nos clients ! Nous
              voulions partager avec vous notre rencontre avec cette jeune pousse qui a
              révolutionné le métier de couturier.
            </P>
          </div>
        ),
        articleLink: 'https://blog.wity.fr/witystartupday-a-la-decouverte-de-tilli-le-service-de-retouches-a-domicile/', // eslint-disable-line max-len
      },
    ],
  },
  {
    index: 12,
    logo: weCasaImg,
    logoCaption: 'Retoucheur Paris Pereire Monceaux',
    articles: [
      {
        title: 'Vive le printemps : 6 idées pour embellir son quotidien',
        content: (
          <div>
            <P>
              {`
              Réparer plutôt que de jeter : c’est une tendance éco-responsable qui s’invite dans nos
              dressing. Pas besoin de se lancer dans une séance shopping ruineuse pour renouveler sa
              garde-robe. Le vrai bon plan, c’est d’offrir une seconde jeunesse à ses pièces
              préférées. C’est vrai, notre placard regorge de pièces qu’on ne porte pas ou plus parce
              que la taille est mauvaise, ou bien parce qu’il y a un petit défaut… La solution, c’est
              de faire retoucher ses vêtements ! On adore le concept de l’appli Tilli, le service de
              retouches à domicile. Un as de la couture vient chez vous pour vous conseiller et
              réajuster une robe, faire un ourlet, pimper un vêtement…
              `}
            </P>
          </div>),
        articleLink: 'https://www.wecasa.fr/mag/printemps-idees/',
      },
    ],
  },
  {
    index: 13,
    logo: zankyouImg,
    logoCaption: 'Prestataires mariage',
    articles: [
      {
        title: 'Après le mariage, quelques petites choses à faire !',
        content: (
          <div>
            <P>
              {`
              Si vous souhaitez donner une seconde vie à votre robe de mariée, envisagez la retouche !
              Le service à domicile, Tilli transforme votre tenue selon vos envies.
              L’un des couturiers modélistes part à votre rencontre et vous conseille dans le changement souhaité.
              Une façon d’insuffler un vent nouveau sur votre robe de mariée !
              `}
            </P>
          </div>),
        articleLink: 'https://www.zankyou.fr/p/apres-mariage-choses-a-faire',
      },
    ],
  },
  {
    index: 14,
    logo: toutMaImg,
    logoCaption: 'Retoucheur Paris',
    articles: [
      {
        title: "Tilli pour se simplifier l'habit",
        content: (
          <div>
            <P>
              {`
              Voici un nouveau service de retouches  qui mêle mode, couture, conseils
              et customisation ! À l'origine de ce concept cousu de bonnes intentions,
              on retrouve Beryl de Labouchere, petite-fille de Geneviève Richard, égérie
              de Balmain dans les années 1950. Et là, on comprend que la mode est aussi question
              de transmission. En janvier 2017, cette jolie fashionista devient entrepreneuse
              et lance la plateforme Tilli. Le principe est simple: vous prenez rendez-vous avec
              une couturière qui vient à domicile chercher vos habits chéris mais flapis
              et vous les rapporte quelques jours après réparés, rafraîchis, voire métamorphosés...
              Et pour coller à son époque, Tilli a mis au point une application pour smartphones,
              sorte de baguette magique de Cendrillon, pour les princesses des temps modernes !
            `}
            </P>
          </div>
        ),
        articleImage: toutMaArticleImg,
        articleImageAlt: 'Article présentant Tilli',
      },
    ],
  },
];

class Press extends React.Component {
  constructor() {
    super();
    this.state = { selectedArticleIndex: 1 };
  }

  render() {
    const article = articlesList.find((articleIt) => articleIt.index === this.state.selectedArticleIndex);
    return (
      <Container>
        <Dots big />
        <Title1 big>Ils parlent de nous</Title1>
        <SubContainer>
          <SelectorContainer>
            <ArticlesSelector
              articlesList={articlesList}
              selectedArticleIndex={this.state.selectedArticleIndex}
              changeArticles={(index) => this.setState({ selectedArticleIndex: index })}
            />
          </SelectorContainer>
          <PressArticle article={article} />
        </SubContainer>
      </Container>
    );
  }
}

export default Press;
